import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link } from "gatsby";
import qs from "qs"
import { Col, Container, Row, Form } from "react-bootstrap";
import Select from 'react-select'
import { isEmpty, filter, find, isArray, isString, get, indexOf } from "lodash"
import DateRangeSelect from '../components/DateRangeSelect/DateRangeSelect'
import MoreFilter from "../components/MoreFilter/MoreFilter";
import { Pagination, connectPagination } from 'react-instantsearch-dom';
import SeoResults, { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "../components/property-search/seoResults"
import { parseSearchUrl, propertyH1, propertyDesc, offPlanPropertyH1, offPlanPropertyH1INTRO } from "../components/property-search/utils";
import { amenitiesCheckboxArray, VirtualViewing, RentalPeriod, Furnishing, bathroomsRange, ParkingRange, resiSalesPropertyType, resiLettingsPropertyType, priceminRangeResiSale, pricemaxRangeResiSale, priceminRangeResiRent, pricemaxRangeResiRent, bedroomsRange, bedroomsMinRange, bedroomsMaxRange, sqftRange, OffPlanPropertyType, completionRange, } from "../components/common/propertyUtils";
import Modules from "../components/modules";

import PaginationPanel from "../components/PaginationPanel/PaginationPanel";
import PropertyFilter from '../components/PropertyFilter/PropertyFilter'
import PropertySlider from '../components/PropertySlider/PropertySlider'
import algoliasearch from "algoliasearch/lite"
import { isMobile } from 'react-device-detect'
//
import {
    connectStats,
    SortBy,
    connectHits,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    ToggleRefinement,
    connectSortBy,
    connectAutoComplete,
    ClearRefinements,
    connectToggleRefinement
} from "react-instantsearch-dom"
import { navigate } from "@reach/router"
import '../components/SearchSelect/SearchSelect.scss';
import { numberFormat } from "../common/site/utils";



//
const updateAfter = 500
const DEBOUNCE_TIME = 500;

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

var includeSold = false;
var includeLet = false;
const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)

const index_name = `${process.env.GATSBY_ALGOLIA_OFF_PLAN_INDEX_NAME}`
//
var defaultArea = "dubai"

// Property results loop
const InfiniteHits = ({ hits, hasPrevious, refinePrevious, hasMore, refineNext, location, userObjects }) => {

    var url_main = typeof window !== 'undefined' ? window.location.pathname : ''
    var page = url_main.split("/").filter(c => c.includes("page-")).length > 0 ? url_main.split("/").filter(c => c.includes("page-"))[0].split("page-").map(c => c)[1] : 1

    return (
        <>
            {(hits.length > 0 && hasPrevious) && (
                <button
                    id="myprevbut"
                    onClick={refinePrevious}
                    disabled={!hasPrevious}
                    className="d-none property_show_more_btn"
                >
                    Show prev
                </button>
            )}
            <Row className="off-plan-page-search">


                {
                    hits.map((hit, i) => {
                        // property details url structure
                        let uriStr = "";
                        let mysale = "";

                        if (hit.search_type === "sales" && (hit.department === 'residential') || (hit.department === 'new_developments')) {
                            uriStr = `off-plan-project-for-sale/`
                            mysale = `myResSaleProp`
                        } else if (hit.search_type === "lettings" && hit.department === 'residential') {
                            uriStr = `property-to-rent/`
                            mysale = `myResRentProp`
                        }
                        // property details url structure
                        return (
                            <Col md={6} lg={3} className="results_page_column">
                                <PropertySlider
                                    price={hit.price && hit.price != null ? numberFormat(hit.price) : ''}
                                    propertyid={hit.objectID}
                                    title={hit.title}
                                    location={hit.display_address}
                                    address={hit.address}
                                    description={hit.description}
                                    bedroom={hit.bedroom}
                                    max_bedrooms={hit.max_bedrooms}
                                    max_price={hit.max_price && hit.max_price != null ? numberFormat(hit.max_price) : ''}
                                    completion_date={hit.completion_date}
                                    bedroomsCount={hit.bedroom}
                                    bathroomsCount={hit.bathroom}
                                    receptionsCount={hit.reception}
                                    propertyVideo={hit.virtual_tour}
                                    propertyImages={hit.images}
                                    thumb={hit.thumbnail}
                                    developer={hit?.developer}
                                    propertyDetailsLink={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                                    propertySearchType={hit.searchType}
                                    priceQualifier={hit.price_qualifier}
                                    price_on_request={hit.price_on_request}
                                    status={hit.status}
                                    sqft={hit.size}
                                    userObjects={userObjects}
                                    area={hit.area}
                                    offPlan={true}
                                />

                            </Col>
                        )
                    })
                }
            </Row>

            {/* {(hits.length > 0 && hasMore) && ( */}
            <div className="load-more-wrap">
                <CustomPaginationRender pagenumber={parseInt(page)} />
                {/* <div className="form__actions m-0">
                        <button className="d-flex btn btn--fill-lightblue"
                            id="mynextbut"
                            onClick={refineNext}
                            disabled={!hasMore}
                        >
                            <div>Load More</div><i className="icon-right"></i>
                        </button>
                    </div> */}
            </div>
            {/* )} */}

        </>
    )
}

const CustomInfiniteHits = connectHits(InfiniteHits)
// Property results loop



const CustomPagination = ({ currentRefinement, nbPages, refine, createURL, pagenumber }) => {
    useEffect(() => {
        document.querySelectorAll(".ais-Pagination a.ais-Pagination-link").forEach(node => {
          // node.href.setAttribute('href', 'javascript:void(0)')
         node.href="javascript:void(0)"
        })
      }, [])
    useEffect(() => {
        if (parseInt(nbPages) <= parseInt(pagenumber)) {
            $('.page-next').attr('disabled', true);
        }
        else {
            $('.page-next').attr('disabled', false);
        }

        if (parseInt(pagenumber) == 1) {
            $('.page-back').attr('disabled', true);
        }
        else {
            $('.page-back').attr('disabled', false);
        }
    }, [pagenumber])


    $(document).on('click', '.ais-Pagination-link', function () {
        if (window) {
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        }
    });

    $("a.ais-Pagination-link").attr("href", "javascript:void(0)")
    // Pagination select box
    const paginationselect = () => {
        var page_val = document.getElementById('pagination-select').value
        navigate(createURL(page_val))
        refine(page_val);

        if (window) {
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        }
    }
    // Pagination select box

    // Next Pagination Button
    const nextpage = () => {
        const url = typeof window !== 'undefined' ? window.location.href : ''
        let pathUri = url.split("page-")
        var next_page_number = parseInt(pagenumber) + 1
        navigate(pathUri[0]+"page-"+next_page_number+"/")
        refine(next_page_number);

        if (window) {
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        }
    }
    // Next Pagination Button

    // Previous Pagination Button
    const prevpage = () => {
        const url = typeof window !== 'undefined' ? window.location.href : ''
        let pathUri = url.split("page-")
        var prev_page_number = parseInt(pagenumber) - 1
        navigate(pathUri[0]+"page-"+prev_page_number+"/")
        refine(prev_page_number);

        if (window) {
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        }
    }
    // Previous Pagination Button

    return (
        <Row>
            <Col md={3} className="d-none d-md-block">
                <button className="page-back" onClick={() => prevpage()}><i className="page-arrow-back"></i> Previous</button>
            </Col>
            <Col md={6} className="text-center">
                <Pagination
                    showFirst={false}
                    showPrevious={false}
                    showNext={false}
                    ariaPrevious='Previous page'
                    first='Next page'
                    showLast={false}
                    // defaultRefinement={props.page}
                    padding={2}
                />
            </Col>
            <Col md={3} className="d-none d-md-block">
                <button className="page-next" onClick={() => nextpage()}>Next <i className="page-arrow-next"></i></button>
            </Col>
        </Row>
    )
}

const CustomPaginationRender = connectPagination(CustomPagination);


// Completion date
const completionSelectbox = ({ items, currentRefinement, refine, department, type }) => {
    var completionListDropdown = completionRange;

    return (
      <Select
        name="completionDate"
        options={completionListDropdown}
        // value={{ value: 0, label: "All" }}
        placeholder="Completion date"
        onChange={(e) => { refine(e.value, e.label) }}
        isSearchable={false}
      />
    )
  }

  const CustomcompletionSelectbox = connectMenu(completionSelectbox)
  // Completion date

// Capitalize
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
// Capitalize

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
    return (
        <div className="filter-search-input-box">
            <div className="dropdown">
                {/* <Select className="react-select" classNamePrefix="react-select" defaultValue={optionsLocation[0]} options={optionsLocation} isSearchable={false} /> */}
                <i className="icon-location"></i>
                <Form.Control className='react-select' type="search" placeholder="Street, area or postcode" id="formBasicEmail"
                    onChange={event => refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())}
                    value={currentRefinement.replace(/-/g, ' ')}
                />
            </div>
        </div>
    )
})
// Searchbox

// Property type
const buildingSelectbox = ({ items, currentRefinement, refine, department, type }) => {
    var propertyListDropdown = OffPlanPropertyType;

    return (
        <Select
            name="building"
            options={propertyListDropdown}
            // value={{ value: 0, label: "All" }}
            placeholder="Property Type"
            onChange={(e) => { refine(e.value, e.label) }}
            isSearchable={false}
        />
    )
}

const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type

// Property Bedrooms
const bedroomSelectBox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {

    let bedroomvalselect = ""
    if (currentRefinement.max !== undefined) {
        // bedroomvalselect = 0
        bedroomvalselect = currentRefinement.max
    } else if (currentRefinement.min !== undefined) {
        bedroomvalselect = currentRefinement.min
    }

    return (
        <Select
            name="bedroom"
            options={bedroomsRange}
            // value={{ value: "", label: "Any" }}
            placeholder="Bedrooms"
            onChange={(e) => {
                if (e.value === "") {
                    refine(e.value)
                } else if (e.value === "0") {
                    refine({
                        max: e.value,
                    })
                } else {
                    refine({
                        min: e.value,
                    })
                }
            }}
            isSearchable={false}
        />
    )
}

const CustombedroomSelectbox = connectRange(bedroomSelectBox)
// Property Bedrooms


// Min Price
const minpriceSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
    type
}) => {
    var minPriceRange = priceminRangeResiSale;
    if (type === "lettings") {
        minPriceRange = priceminRangeResiRent;
    }
    if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
        minPriceRange = minPriceRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
    }

    minpriceval = currentRefinement.min
    return (
        <Select
            name="minprice"
            options={minPriceRange}
            isSearchable={false}
            placeholder="Min Price"
            onChange={(e) => {
                minpriceval = e.value
                if (e.value === "0") {
                    if (maxpriceval !== "0") {
                        refine({
                            max: maxpriceval,
                        })
                    } else refine(e.value, e.label)
                } else {
                    refine({
                        min: e.value,
                        max: maxpriceval,
                    })
                }
            }}
        />
    )
}

const CustomminpriceSelectbox = connectRange(minpriceSelectbox)
// Min Price

// Max Price
const maxpriceSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
    type
}) => {
    var maxPriceRange = pricemaxRangeResiSale;
    if (type === "lettings") {
        maxPriceRange = pricemaxRangeResiRent;
    }
    let maxpricedisable = ''
    if (currentRefinement.min !== undefined) {
        maxPriceRange = maxPriceRange.filter((x) => x.value == "0" || parseInt(x.value) > parseInt(currentRefinement.min))
    }
    if (currentRefinement.min == "400000") {
        maxpricedisable = 'disabled'
    }
    maxpriceval = currentRefinement.max
    return (
        <Select
            name="maxprice"
            options={maxPriceRange}
            defaultValue={{ value: "0", label: "Max Price" }}
            placeholder="Max Price"
            classNamePrefix={"select-opt"}
            isSearchable={false}
            onChange={(e) => {
                maxpriceval = e.value
                if (e.value === "0") {
                    if (minpriceval) {
                        refine({
                            min: minpriceval,
                        })
                    } else refine(e.value, e.label)
                } else {
                    refine({
                        min: minpriceval,
                        max: e.value,
                    })
                }
            }}
        />
    )
}

const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox)
// Max Price



// Sort by
const sortbyStyle = {
    control: base => ({
        ...base,
        boxShadow: "none",
        border: "none",
    }),
}

const CustomSortBy = ({ items, refine, currentRefinement }) => (
    <Select
        isSearchable={false}
        styles={sortbyStyle}
        options={items}
        classNamePrefix={"react-select"}
        className={"react-select"}
        defaultValue={currentRefinement || ''}
        onChange={(e) => { refine(e.value, e.label) }}
        placeholder={
            <div className="custom-select__placeholder"> {"Most Recent"}</div>
        }
    />
);

const CustomSortByFilter = connectSortBy(CustomSortBy);
// Sort by


// No Results
const NoStats = ({ processingTimeMS, nbHits, location, status, department, OpenMember }) => {
    return (
        <>
            {nbHits === 0 &&
                <>

                    <div className="no-properties-text">
                        <p className="mb-0">Unfortunately, we do not currently have any properties that match your search criteria.</p>
                        <p>We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the bar above.
                        </p>
                    </div>
                </>
            }
        </>
    )
}

const CustomNoStats = connectStats(NoStats)
// No Results

// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Create URL
export const createURL = (props, state, location) => {
    let myarea = state.menu

    const isDefaultRoute =
        !state.query &&
        !state.SortBy &&
        state.page === 1 &&
        state.menu &&
        state.range &&
        !state.range.price &&
        !state.range.bedroom &&
        !state.range.bathroom


    if (isDefaultRoute) {
        return ""
    }

    var areaPath = "in-dubai/"
    var bedroomPath = ""
    var bathroomPath = ""
    var pricePath = ""
    var pagePath = ""
    var propertypath = ""
    var completionDatePath = ""
    var sortPath = ""
    var amenitiesPath = ""
    var sizePath = ""
    var furnishedPath = ""
    var viewingPath = ""
    var pqpath = ""
    var parkingPath = ""
    // if (state?.refinementList?.search_areas) {
    //   if (state?.refinementList?.search_areas) {
    //     areaPath = "in-" + encodeURIComponent(decodeURIComponent(state?.refinementList?.search_areas)) + "/"
    //   }
    // }

    if (state?.refinementList?.search_areas) {
        if (typeof state.refinementList.search_areas === "object") {
            if (state.refinementList.search_areas.length > 0) {
                var area_list = state.refinementList.search_areas.filter(function (item, index, inputArray) {
                    return inputArray.indexOf(item) == index;
                });
                if (area_list.length > 0) {
                    areaPath = `in-${area_list.map(encodeURIComponent).join('-and-')}/`
                }
            }
        } else {
            areaPath = `in-${state?.refinementList?.search_areas}/`
        }
        // if (state.refinementList?.amenities?.length > 0) {
        //   amenitiesPath = `with-${state.refinementList?.amenities}/`
        // }
    }
    const queryParameters = {}
    // Property path
    if (state.menu) {
        if (myarea["building"]) {
            propertypath = "type-" + myarea["building"] + "/"
        }
        if (myarea["completionYear"]) {
            completionDatePath = "date-" + myarea["completionYear"] + "/"
          }
    }
    // Property path
    // if (state.amenities) {
    //   if (typeof state.amenities === "object") {
    //     if (state.amenities.length > 0) {
    //       var amenities_list = state.amenities.filter(function (item, index, inputArray) {
    //         return inputArray.indexOf(item) == index;
    //       });
    //       if (amenities_list.length > 0) {
    //         amenitiesPath = `with-${amenities_list.map(encodeURIComponent).join('-and-')}/`
    //       }
    //     }
    //   } else {
    //     amenitiesPath = `with-${state.amenities}/`
    //   }

    // }
    // Bedrooms & Price range path
    if (state.range) {
        // Bedrooms
        if (state.range.hasOwnProperty('bedroom') && parseInt(state.range.bedroom.max) == 0) {
            bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
        }
        // if (state.range.bedroom) {
        //   if (state.range.bedroom.min) {
        //     bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
        //   }
        // }
        if (state.range.bathroom) {
            if (state.range.bathroom.min) {
                bedroomPath = state.range.bathroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
            }
        }
        // Price
        if (state.range?.price) {
            if (state.range.price.min && state.range.price.max) {
                pricePath = "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
            } else if (state.range.price.max) {
                pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
            } else if (state.range.price.min) {
                pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
            }
        }

        if (state.range?.size) {
            if (state.range.size.min && state.range.size.max) {
                sizePath = "size-" + state.range.size.min + "-both-" + state.range.size.max + '/' //queryParameters.price_min = state.range.price.min;
            } else if (state.range.size.max) {
                sizePath = "sizetill-" + state.range.size.max + '/' //queryParameters.price_max = state.range.price.max;
            } else if (state.range.size.min) {
                sizePath = "sizestarting-" + state.range.size.min + '/' //queryParameters.price_max = state.range.price.max;
            }
        }


        // Bedrooms
        if (state.range?.bedroom) {
            if (state.range.bedroom.min && state.range.bedroom.max) {
                bedroomPath = "from-" + state.range.bedroom.min + "-bedrooms-to-" + state.range.bedroom.max + '-bedrooms/' //queryParameters.price_min = state.range.price.min;
            } else if (state.range.bedroom.max) {
                bedroomPath = "to-" + state.range.bedroom.max + '-bedrooms/' //queryParameters.price_max = state.range.price.max;
            } else if (state.range.bedroom.min) {
                bedroomPath = "from-" + state.range.bedroom.min + '-bedrooms/' //queryParameters.price_max = state.range.price.max;
            }
        }

    }
    // Bedrooms & Price range path
    // Size
    // if (state.range?.size) {
    //   if (state.range.size.min && state.range.size.max) {
    //     sizePath = "size-" + state.range.size.min + "-to-" + state.range.size.max + '/' //queryParameters.price_min = state.range.price.min;
    //   }
    // }
    // if (state?.refinementList?.furnished) {
    //   furnishedPath = "furnished-" + state.refinementList.furnished + '/' //queryParameters.price_min = state.range.price.min;
    // }
    if (state.menu) {
        if (myarea["furnished"]) {
            furnishedPath = "furnished-" + myarea["furnished"] + "/"
        }
    }
    if (state.menu) {
        if (myarea["price_qualifier"]) {
            pqpath = "rental-period-" + myarea["price_qualifier"] + "/"
        }
    }
    if (state.menu) {
        if (myarea["viewing"]) {
            viewingPath = "viewing-" + myarea["viewing"] + "/"
        }
    }
    // if (state?.refinementList?.viewing) {
    //   viewingPath = "viewing-" + state.refinementList.viewing + '/' //queryParameters.price_min = state.range.price.min;
    // }

    if (state?.range?.parking) {
        if (state.range.parking.min) {
            parkingPath = "parking-" + state.range.parking.min + '/' //queryParameters.price_min = state.range.price.min;
        }
    }
    // Sort path
    if (state.sortBy) {
        if (state.sortBy === index_name + "_price_asc") {
            sortPath = "sortby-price-asc/";
        }
        if (state.sortBy === index_name + "_price_desc") {
            sortPath = "sortby-price-desc/";
        }
        if (state.sortBy === index_name + "_bedroom_asc") {
            sortPath = "sortby-bedroom-asc/";
        }
        if (state.sortBy === index_name + "_bedroom_desc") {
            sortPath = "sortby-bedroom-desc/";
        }
        if (state.sortBy === index_name + "_most_recent") {
            sortPath = "sortby-most-recent/";
        }
    }
    // Sort path

    // Page path
    if (state.page && state.page !== 1) {
        pagePath = `page-${state.page}/`
    }
    // Page path

    const queryString = qs.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: "repeat",
    })


    let myUrl = `${areaPath}${propertypath}${bedroomPath}${bathroomPath}${completionDatePath}${pricePath}${sizePath}${amenitiesPath}${furnishedPath}${pqpath}${viewingPath}${parkingPath}${queryString}${sortPath}${pagePath}`


    // if ("/" === myUrl.substr(myUrl.length - 1))
    //   myUrl = myUrl.substr(0, myUrl.length - 1)
    //console.log("propsprops", props)
    if ("/" === myUrl.substr(myUrl.length - 1))
        myUrl = myUrl.substr(0, myUrl.length - 1)

    if (!myUrl.endsWith('/'))
        myUrl = myUrl + '/';

    myUrl = myUrl.toLowerCase();
    var urlstr = "for-sale";
    if (typeof window !== 'undefined' && window.location.href.indexOf("to-rent") != -1) {
        urlstr = "to-rent";
    }
    return `/off-plan-projects/${urlstr}/${myUrl}`

}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState, location) =>
    searchState ? `${createURL(props, searchState, location)}` : ""

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const urlToSearchState = location => {
    if (location) {
        var query = ""
        let pathUri_main = ''

        let a1 = location.pathname.indexOf("/off-plan-projects/for-sale") > -1
        let a2 = location.pathname.indexOf("/off-plan-projects/for-rent") > -1

        if (a1 == true) {
            pathUri_main = location.pathname.split("/off-plan-projects/for-sale")
        } else if (a2 == true) {
            pathUri_main = location.pathname.split("/off-plan-projects/for-rent")
        } else {
            pathUri_main = location.pathname.split(location)
        }

        var areaVal = ""
        var bedVal = 10
        var bathVal = 10
        var sqftVal = ""
        var minpriceVal = ""
        var maxpriceVal = ""
        var minbedVal = ""
        var maxbedVal = ""
        var pageVal = ""
        var propertyval = ""
        var completiondateval = ""
        var sortVal = ""
        var sortVal_filt = ""
        var amenitiesval = ""
        var minsqftVal = ""
        var maxsqftVal = ""
        var furnishval = ""
        var parkingval = 10
        var viewingval = ""
        var price_qualifier_val = ""
        if (pathUri_main[1]) {
            // following could be regexp
            let pathUri = pathUri_main[1].split("/")

            //lets loop
            for (let vi = 1; vi <= pathUri.length; vi++) {
                // check for area
                if (typeof pathUri[vi] === "undefined") {
                    continue
                }

                // Property type
                if (pathUri[vi].indexOf("type-") >= 0) {
                    propertyval = pathUri[vi].replace("type-", "")
                }
                // Area
      // Completion date
      if (pathUri[vi].indexOf("date-") >= 0) {
        completiondateval = pathUri[vi].replace("date-", "")
      }

                if (pathUri[vi].indexOf("in-") >= 0 && pathUri[vi].indexOf("in-") <= 1) {
                    let areaStr = pathUri[vi]
                    areaVal = areaStr.replace("in-", "")
                    if (areaVal === defaultArea) areaVal = ""
                    if (areaVal.includes("-and-")) {
                        areaVal = areaVal.split("-and-")
                    }
                }

                // Price
                if (
                    pathUri[vi].indexOf("between-") >= 0 ||
                    pathUri[vi].indexOf("over-") >= 0 ||
                    pathUri[vi].indexOf("under-") >= 0
                ) {
                    let priceFilt1 = pathUri[vi].split("over-")
                    if (priceFilt1[1]) {
                        minpriceVal = priceFilt1[1]
                    }
                    let priceFilt2 = pathUri[vi].split("under-")
                    if (priceFilt2[1]) {
                        maxpriceVal = priceFilt2[1]
                    }
                    let priceFilt3 = pathUri[vi].split("between-")
                    if (priceFilt3[1]) {
                        let priceFilt4 = priceFilt3[1].split("-and-")
                        minpriceVal = priceFilt4[0]
                        maxpriceVal = priceFilt4[1]
                    }
                }



                // Bedrooms Range
                if (
                    pathUri[vi].indexOf("from-") >= 0 ||
                    pathUri[vi].indexOf("to-") >= 0 ||
                    pathUri[vi].indexOf("bedrooms-to-") >= 0
                ) {
                    let bedroomFilt1 = pathUri[vi].split("from-")
                    if (bedroomFilt1[1]) {
                        var splitbed1 = bedroomFilt1[1].split("-bedrooms")
                        if (bedroomFilt1[1].indexOf("to-") <= 0) {
                            minbedVal = splitbed1[0]
                        }
                    }
                    let bedroomFilt2 = pathUri[vi].split("to-")
                    if (bedroomFilt2[0] == "" && bedroomFilt2[1]) {
                        var splitbed2 = bedroomFilt2[1].split("-bedrooms")
                        maxbedVal = splitbed2[0]
                    }
                    let bedroomFilt3 = pathUri[vi].split("bedrooms-")
                    if (bedroomFilt3[0] && bedroomFilt3[1]) {
                        if (bedroomFilt3[0].indexOf("from-") >= 0) {
                            var splitbed3 = bedroomFilt3[0].split("from-")
                            minbedVal = splitbed3[1].replace(/-/g, "")
                        }
                        if (bedroomFilt3[1].indexOf("to-") >= 0) {
                            var splitbed4 = bedroomFilt3[1].split("to-")
                            maxbedVal = splitbed4[1].replace(/-bedrooms/g, "")
                        }


                    }
                }
                // Bedrooms Range


                //Size
                // if (
                //   pathUri[vi].indexOf("size-") >= 0
                // ) {
                //   let priceFilt3 = pathUri[vi].split("size-")
                //   if (priceFilt3[1]) {
                //     let priceFilt4 = priceFilt3[1].split("-to-")
                //     minsqftVal = priceFilt4[0]
                //     maxsqftVal = priceFilt4[1]
                //   }
                // }
                // Bedrooms
                if (pathUri[vi].indexOf("-and-more-bedrooms") >= 0) {
                    bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
                    // sortVal = index_name+"_bedrooms"
                }
                if (pathUri[vi].indexOf("-and-more-bathrooms") >= 0) {
                    bathVal = pathUri[vi].replace("-and-more-bathrooms", "")
                    // sortVal = index_name+"_bedrooms"
                }
                if (pathUri[vi].indexOf("studio") >= 0) {
                    // its studio bedroom
                    bedVal = 0
                }

                // Sort by
                if (pathUri[vi].indexOf("sortby-") >= 0) {
                    sortVal_filt = pathUri[vi].replace("sortby-", "")

                    if (sortVal_filt === "price-asc") {
                        sortVal = index_name + "_price_asc"
                    }

                    if (sortVal_filt === "price-desc") {
                        sortVal = index_name + "_price_desc"
                    }
                    if (sortVal_filt === "most-recent") {
                        sortVal = index_name
                    }
                }

                // Page
                if (pathUri[vi].indexOf("page") >= 0) {
                    pageVal = pathUri[vi].replace("page-", "")
                }
            }
        }
    }

    // Property type
    if (propertyval) {
        query += `&menu[building]=` + propertyval
    }

  // Completion date
  if (completiondateval) {
    query += `&menu[completionYear]=` + completiondateval
  }
    // if (amenitiesval) {
    //   query += `&refinementList[amenities]=` + amenitiesval
    // }

    // if (amenitiesval) {
    //   for(let v=0; v <= amenitiesval.length; v++){
    //       if (typeof amenitiesval[v] !== "undefined") {
    //           query += `&refinementList[amenities]=` + amenitiesval[v]
    //       }
    //   }
    // }

    //console.log("search_areassearch_areas",areaVal)
    // Area
    if (areaVal) {
        if (isArray(areaVal)) {
            areaVal.map(area => {
                query += `&refinementList[search_areas]=${area.toLowerCase()}`
            })
        } else {
            query += `&refinementList[search_areas]=${areaVal}`
        }
    }
    // Price
    if (minpriceVal) {
        query += `&range[price][min]=` + minpriceVal
    }

    if (maxpriceVal) {
        query += `&range[price][max]=` + maxpriceVal
    }
    // Bedrooms
    if (minbedVal) {
        query += `&range[bedroom][min]=` + minbedVal
    }

    if (maxbedVal) {
        query += `&range[bedroom][max]=` + maxbedVal
    }

    // if (bedVal == 0) {
    //   query += `&range[bedroom][min]=` + 0
    //   query += `&range[bedroom][max]=` + 0
    // } else if (bedVal < 10) {
    //   query += `&range[bedroom][min]=` + bedVal
    // }

    // Bathrooms
    if (bathVal == 0) {
        query += `&range[bathroom][min]=` + 0
        query += `&range[bathroom][max]=` + 0
    } else if (bathVal < 10) {
        query += `&range[bathroom][min]=` + bathVal
    }

    // Page
    if (pageVal) {
        query += `&page=${pageVal}`
    }

    // Sort by
    if (sortVal) {
        query += `&sortBy=` + sortVal
    }

    // console.log("query", query)

    return qs.parse(query)
}
// URL to searchstate



class OffPlanSearchResultsTemplate extends React.Component {

    constructor(props) {
        super(props);
    }
    //
    state = {
        searchState: urlToSearchState(typeof window !== 'undefined' ? window.location : ''),
        refinementList: {
            amenities: [],
            search_areas: [],
        },
        userObjects: [],
        mapview: false,
        isOpen: false,
        popupType: "",
        membDet: ""
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.setState({ searchState: urlToSearchState(this.props.location) })
        }
    }

    onSearchStateChange = searchState => {
        clearTimeout(this.debouncedSetState)
        this.debouncedSetState = setTimeout(() => {
            navigate(searchStateToUrl(this.props, searchState, this.props.locationname), searchState)
        }, updateAfter)
        this.setState({ searchState })
    }

    componentDidMount() {
        $(document).mouseup(function (e) {
            var container = $(".price-filter-dropdown")
            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".price-filter-dropdown").removeClass("active")
            }
        })
    }
    //

    render() {

        const sortOptions = [{
            value: "Highest Price First",
            label: "Highest Price First"
        },
        {
            value: "Lowest Price First",
            label: "Lowest Price First"
        },
        ]
        const minPrice = [{ value: "", label: "Min Price" }]
        const maxPrice = [{ value: "", label: "Max Price" }]
        const bedroomsOpt = [{ value: "", label: "Bedrooms" }, { value: "1", label: "1+" }, { value: "2", label: "2+" }, { value: "3", label: "3+" }, { value: "4", label: "4+" }]
        const propertyTypes = [{ value: "", label: "Property Type" }, { value: "apartment", label: "Apartment" }, { value: "house", label: "House" }, { value: "flat", label: "Flat" }]
        // Capitalize
        const capitalize = (s) => {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        }
        // Capitalize

        const searchParams = parseSearchUrl(this.props.ptype, this.props.fullpathname, this.props.pcategorytype , this.props.department);

        let propertyType = "";
        if (searchParams.type == "") {
            propertyType = "properties "
        } else {
            propertyType = searchParams.type
        }

        const pType = searchParams.pType;

        const actionStr = ACTION_TYPES[pType];

        var description = `Explore our latest collection of ${propertyType.toLowerCase()} ${actionStr} ${searchParams.areas}. Contact Arabian Estates Properties estate agents in ${searchParams.areas} to find the right property for you.`

        const isPageLoadBg = (typeof this.props.fullpathname === "undefined");


        const h1 = propertyH1(searchParams, this.props.pcategorytype);

        const h1desc = propertyDesc(searchParams, this.props.pcategorytype);
        const h1descintro = offPlanPropertyH1INTRO(searchParams, this.props.pcategorytype, "Off Plan properties");
        const areaName = searchParams.areas;

        // const saveSearchParams = savedSearchParams(searchParams);

        var split_sort_by = this.props.location.pathname.split('sortby-');
        var sort_by = split_sort_by.length > 1 ? split_sort_by[1] : '';
        var replaceH1 = h1.replace(/-/g, ' ');

        var replaceh1SECINTRO = h1descintro.replace(/-/g, ' ');

        //replaceH1 = replaceH1.replace("Essex", "Essex and Kent");
        replaceH1 = replaceH1.replace(/And/g, "and");
        replaceH1 = replaceH1.replace("Central a c heating", "Central A/C & Heating")

        var replaceh1SEC = h1.replace(/-/g, ' ');
        replaceh1SEC = replaceh1SEC.replace(/And/g, "and");
        replaceh1SEC = replaceh1SEC.replace("Central a c heating", "Central A/C & Heating")

        replaceh1SECINTRO = replaceh1SECINTRO.replace(/And/g, "and");
        replaceh1SECINTRO = replaceh1SECINTRO.replace("Central a c heating", "Central A/C & Heating")
        //replaceh1SEC = replaceh1SEC.replace("Essex", "Essex and Kent");
        var areaReplace = capitalize(areaName).replace("%20", " ")

        var offplantitle = replaceH1.replace("for sale in Dubai", "in Dubai | New Developments");

        const GQLPage = this.props.GQLPageData
        const GQLModules = GQLPage?.add_components
        return (
            <>
            <Container>
                <Row>
                    <Col>
                        {!isPageLoadBg &&
                            <SeoResults offplan={true} title={"Off Plan " + offplantitle} searchParams={searchParams} description={description} location={this.props.fullpathname} pcategorytype={this.props.pcategorytype} />
                        }

                        <InstantSearch
                            indexName={index_name}
                            searchClient={searchClient}
                            searchState={this.state.searchState}
                            onSearchStateChange={this.onSearchStateChange}
                            createURL={createURL}
                            routing="true"
                        >
                            <div className="d-none">
                                {/* <CustomAmenitiesSlider attribute="amenities" limit={100}/> */}
                                <RefinementList
                                    attribute="search_type"
                                    defaultRefinement={[this.props.ptype]}
                                />
                                <RefinementList
                                    attribute="department"
                                    defaultRefinement={[this.props.pcategorytype]}
                                />
                                <RefinementList
                                    attribute="publish"
                                    defaultRefinement={[true]}
                                />
                            </div>
                            <Configure
                                hitsPerPage={12}
                            />
                            <PropertyFilter locationname={this.props.locationname} searchState={this.state.searchState} type={this.props.ptype} ptypetag={this.props.ptypetag} department={this.props.pcategorytype} sort_by={sort_by}
                                sales={this.props.ptype === "sales" ? true : false} propertyCategory="off-plan" />

                            <div className='search-select d-none'>
                                <div className='search-wrapper'>
                                    <div className='location-search flex-grow-1'>
                                        <SearchBox />
                                        <CustomminpriceSelectbox attribute="price" type={this.props.ptype} />
                                        <CustommaxpriceSelectbox attribute="price" type={this.props.ptype} />
                                        <CustombedroomSelectbox attribute="bedroom" />
                                        {/* <CustombuildingSelectbox attribute="building" type={this.props.ptype} department={this.props.pcategorytype} /> */}
                                        <CustomcompletionSelectbox attribute="completionYear" />
                                    </div>
                                </div>
                            </div>
                            <div className="search-head">
                                <div className="property-sale">
                                    <div className="result-details">
                                        <div className="lft-content">
                                        <h1 className="h1">
                                                <span className="total-result">
                                                    <CustomStats />
                                                </span>Off plan {replaceH1}
                                            </h1>
                                            {/* <p>Explore our collection of {replaceh1SEC} with Arabian Estates Properties. To filter these results, use our filter above, or for more information about properties for {this.props.ptype == "sales" ? "sale" : "rent"} in Dubai, <Link to="/contact-arabian-estates/">please contact us</Link>.</p> */}
                                            {/* <p>Explore our {replaceh1SEC}. For more information about the {replaceh1SECINTRO}, please get in touch with <Link to="/contact-arabian-estates/">Arabian Estates leading real estate agents in Dubai</Link>.</p> */}
                                            <p>Whether you are a first-time home buyer, investor, or simply looking for your next family home in Dubai, we have a large portfolio of new developments and off plan properties for sale in Dubai. Choose from a selection of apartments and villas with Arabian Estates. Use our custom filters to refine your search to explore our latest off plan projects in Dubai, or contact us for more information on <Link to ="/property-services/buy-property/">buying property in Dubai</Link>.</p>

                                            {/* <p>
                                                Lorem ipsum dolor sit amet consectetur adipiscing elit quisque vitae nisl semper, aliquam nibh non...
                                                <Link to="/" className="read-more">
                                                    read more
                                                </Link>
                                            </p> */}
                                        </div>
                                        <div className="rtl-content d-none d-md-flex">
                                            <span className="sort-by flex-shrink-0">
                                                Sort by:
                                            </span>
                                            <CustomSortByFilter
                                                defaultRefinement={index_name}
                                                className="search-sory-by"
                                                items={[
                                                    { value: index_name, label: "Most Recent" },
                                                    { value: index_name + "_price_desc", label: "Highest Price First" },
                                                    { value: index_name + "_price_asc", label: "Lowest Price First" }
                                                ]}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CustomInfiniteHits
                                location={this.props.fullpathname}
                                tag={this.props.ptypetag}
                                userObjects={this.props.userObjects}
                            />
                            <CustomNoStats
                                location={this.props.fullpathname}
                                status={this.props.pstatustype}
                                department={this.props.pcategorytype}
                            />


                        </InstantSearch>


                        {/* <PaginationPanel /> */}
                    </Col>
                </Row>
            </Container>
            <Modules {...GQLPage} layout="offplan_static_template"/>
            </>
        )
    }
}

export default OffPlanSearchResultsTemplate
