import React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/layout"
import LandingBannerOffplan from "../../../components/Banner/LandingBannerOffplan";
import OffPlanSearchResultPage from "../../../templates/off-plan-search-results-template";

const SearchResultResiSales = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    const GQLPage = props.data?.glstrapi?.pages

    return (
        <div className='search-results-page off-plan-results-static-page'>
            <Layout>
                <LandingBannerOffplan {...GQLPage && GQLPage.length > 0 ? GQLPage[0] : ''} />
                <div className="sticky-btn-cta"><a class="btn samepage-form-link" href="#enquiry-form-section">Enquiry Now</a></div>
                <section className={scroll ? "search-banner s_earch-banner--fixed" : "search-banner"}>
                    <OffPlanSearchResultPage
                        GQLPageData={GQLPage && GQLPage.length > 0 ? GQLPage[0] : ''}
                        locationname="/off-plan-projects/for-sale/"
                        pcategorytype="residential"
                        multipcategorytype={['residential']}
                        ptype="sales"
                        ptypetag="for-sale"
                        pstatustype={['for-sale', 'sold-stc', 'sold', 'under-offer']}
                        fullpathone={fullpathone}
                        fullpathname={fullpath}
                        {...props}
                        psearch="Sales"
                        department="off_plan"
                    />
                </section>
            </Layout>
        </div>
    );
};

export default SearchResultResiSales;


export const pageQuery = graphql`
  query GetArticleOffPlanStatic {
    glstrapi {
      pages(where: {alias: "off-plan-results"}) {
        alias
        banner_content
        banner_cta_label
        banner_right_content
        banner_search_default_select
        choose_form
        content
        layout
        page_class
        show_reviews
        show_search
        title
        imagetransforms
        id
        seo_meta {
          title
          description
          keywords
          image {
            url
          }
        }
        menu {
          label
          id
        }
        banner_image {
          url
          alternativeText
          url_sharp {
            childImageSharp {
              gatsbyImageData(
                formats: WEBP
                width: 1800
                quality: 100
                layout: CONSTRAINED
                transformOptions: { cropFocus: CENTER, fit: COVER }
              )
            }
          }
        }
        banner_cta_link {
          id
          slug
        }
        add_components {
          __typename
          ... on GLSTRAPI_ComponentComponentsSelectCollection {
            choose_collection
            title
            description
          }
          ... on GLSTRAPI_ComponentComponentsIntroSection {
            text_block {
              title
              description
              title_below_image {
                url
              }
            }
            link {
              id
              label
              menu_item {
                id
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsShowCustomerReviews {
            show
          }
          ... on GLSTRAPI_ComponentComponentsTextBlockWithImage {
            layout_type
            Button_CTA {
              label
              menu_item {
                id
              }
              Secondary_Url
            }
            media_block {
              Video_Url
              media_file {
                url
                alternativeText
              }
            }
            text_block {
              description
              title
            }
          }
          ... on GLSTRAPI_ComponentComponentsFullWidthTwoColumnBlock {
            column_1_cta_label
            column_1_sub_title
            column_1_title
            column_2_sub_title
            column_2_title
            column_1_cta_link {
              id
            }
            column_2_cta_label
            column_2_cta_link {
              id
            }
          }
          ... on GLSTRAPI_ComponentComponentsShowModule {
            id
            module {
              module_type
              section_name
              add_new_text_with_image_module {
                layout_type
                text_block {
                  description
                  title
                }
                media_block {
                  media_file {
                    url
                    alternativeText
                    url_sharp {
                      childImageSharp {
                        gatsbyImageData(
                          formats: WEBP
                          width: 500
                          quality: 100
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER, fit: COVER }
                        )
                      }
                    }
                  }
                }
                Button_CTA {
                  label
                  menu_item {
                    id
                  }
                  Secondary_Url
                }
              }
              add_new_stats_or_service {
                description
                icon_class_name
                title
                properties_count
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsFaq {
            _id
            faq_intro
            choose_faqs {
              Answer
              Question
            }
          }
          ... on GLSTRAPI_ComponentComponentsContentWithPoints {
            Add_Content {
              Content
              Title
            }
          }
        }
      }
    }
  }
`
